import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../axios/utils";

const cancelTokenHandlerObject = {
    getPlayhalaTransactions: createCancelTokenHandler('getPlayhalaTransactions'),
    getPlayhalaActivitiesLog: createCancelTokenHandler('getPlayhalaActivitiesLog')
}

export function getPlayhalaProfile () {
    return axios({
        url: `v1/admin/playhala/profile`,
        method: 'get'
    })
}

export function getPlayhalaTransactions (page, filters = {}, sorts = []) {
    let queries = makeQuery(page, filters, sorts)

    if (queries.length > 0) queries = `?${  queries.join('&')}`
    return axios({
        url: `v1/admin/playhala/transactions${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getPlayhalaTransactions'].handleRequestCancellation().token
    })
}

export function getPlayhalaActivitiesLog (page, filters = {}, sorts = []) {
    let queries = makeQuery(page, filters, sorts)

    if (queries.length > 0) queries = `?${  queries.join('&')}`
    return axios({
        url: `v1/admin/playhala/activity-logs${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getPlayhalaActivitiesLog'].handleRequestCancellation().token
    })
}