<template>
  <div class="playhala-activities-log" :class="[{'fixed-draggable-dynamic-table-wrapper-height': $route.name === 'playhalaActivitiesLog'}]">
    <draggable-dynamic-table ref="playhalaActivitiesLogsListTable"
                             :in-modal="$route.name !== 'playhalaActivitiesLog'"
                             :columns="columnsLabel"
                             :options="options"
                             @descriptionClicked="handleDescriptionClicked"
                             v-model="data"/>

    <!--  show product details prompt  -->
    <vs-prompt
      class="p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showDetailsPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('activityLogs.list.dialogs.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showDetailsPromptStatus = false">
              <vs-icon style="font-size: 25px"
                       icon="icon-x-circle"
                       icon-pack="feather"
                       color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <ul>
          <li v-for="(key, key_index) in Object.keys(details)"
              :key="key_index">
            {{ key_index + 1  }}. {{ details[key] }}
          </li>
        </ul>
      </div>

    </vs-prompt>
  </div>
</template>

<script>
import axios from 'axios'
import {convertUtcToLocale} from "../../../../assets/js/functions";
import {getUserActivitiesLog} from "../../../../http/requests/users/users";
import activityLogs from "../../../../mixins/activityLogs";
import {getPlayhalaActivitiesLog} from "@/http/requests/playhala";

export default {
  name: 'playhalaActivitiesLog',
  props: {
    userId: {
      type: Number,
      default: 0
    },
  },
  metaInfo () {
    return {
      title: this.$t('playhala.logs.title')
    }
  },
  mixins: [activityLogs],
  data () {
    return {
      showDetailsPromptStatus: false,
      options: {
        // id: 'playhalaActivitiesLogsListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'date',
          i18n: 'activityLogs.list.table.header.date',
          width: '150px',
          minWidth: 150,
          align: 'center',
          locked: true
        },
        {
          field: 'description',
          i18n: 'activityLogs.list.table.header.description',
          width: 'calc((100% / 4) * 3)',
          minWidth: 350,
          align: 'left',
          locked: true,
          valueClickEvent: 'descriptionClicked'
        },
        {
          field: 'user',
          i18n: 'activityLogs.list.table.header.user',
          width: 'calc((100% / 4) * 1)',
          minWidth: 200,
          align: 'left',
          locked: true
        },
        {
          field: 'rowNumber',
          i18n: 'activityLogs.list.table.header.rowNumber',
          align: 'center',
          width: '70px',
          minWidth: 70,
          fixed: true,
          footer: {}
        }
      ],
      filters: {},
      sorts: ['order[0]=date,desc'],
      data: [],
      details: {},
      loadingTimer: 0,
      actions: {
        leftToolbar: [
          {
            id: 'routeBackBTN',
            route: {name: 'playhalaProfile'},
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      }
    }
  },
  created () {
    if (this.$route.name !== 'playhalaActivitiesLog') {
      const backIndex = this.actions.leftToolbar.map(elm => elm.id).indexOf('routeBackBTN')
      if (backIndex > -1) {
        this.actions.leftToolbar.splice(backIndex, 1)
      }
    }
    // if (this.$route.name === 'playhalaActivitiesLog') {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    // }
    this.$store.dispatch('setPageTitle', this.$t('playhala.logs.title'))
    this.getLogs()
  },
  methods: {
    getLogs () {
      if (this.$refs.playhalaActivitiesLogsListTable && this.data.length === 0) this.$refs.playhalaActivitiesLogsListTable.loadMoreStatus = 'FirstLoad'

      getPlayhalaActivitiesLog(1, this.filters, this.sorts).then((response) => {
        const logs = response.data.data
        logs.forEach((log) => {
          const description = this.getActivityLog(log.subject)
          const data = {
            id: log.id,
            rowNumber: this.data.length + 1,
            user: `${log.user.name || ''} ${log.user.family || ''}`,
            description: description,
            details: [],
            date: {
              value: convertUtcToLocale(log.date, this.$localeValidator('moment.dateTime')),
              classes: this.$vs.rtl ? 'rtl' : 'ltr'
            }
          }
          if (this.showDescriptionActions.indexOf(log.subject.split('.')[1]) > -1) {
            const differenceValue = JSON.parse(log.difference)
            if (differenceValue) {
              const oldValue = JSON.parse(log.old)
              Object.keys(differenceValue || {}).forEach((item) => {
                data.details.push(this.getFieldChangeDescription(log.subject, item, oldValue[item], differenceValue[item]))
              })
            } else {
              data.details.push(this.$t('activityLogs.list.dialogs.noChange'))
            }
          }
          if (data.details.length > 0) {
            data.description = {
              value: description,
              classes: 'text-primary'
            }
          }

          this.data.push(data)
        })

        const row_index = this.columnsLabel.map((e) => {
          return e.field
        }).indexOf('rowNumber')
        this.columnsLabel[row_index].footer.value = logs.length

        if (this.$refs.playhalaActivitiesLogsListTable) this.$refs.playhalaActivitiesLogsListTable.loadMoreStatus = ''
      }).catch(error => {
        console.log(error)
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {

          if (this.$refs.playhalaActivitiesLogsListTable && !axios.isCancel(error)) this.$refs.playhalaActivitiesLogsListTable.loadMoreStatus = 'Danger'
        }
      })
    },
    handleDescriptionClicked(row) {
      if (this.data[row.index].details.length > 0) {
        this.showDetailsPromptStatus = true
        this.details = this.data[row.index].details
      }
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss">
.playhala-activities-log {

  &:not(.fixed-draggable-dynamic-table-wrapper-height) {
    height: 100%;
  }
}
</style>
